import React from 'react'
import Navbar from '../_global/Navbar'
import Trading from './Trading'
import Footer from '../_global/Footer'

const index = () => {
    return (
        <div className="TradingPage">
            <Navbar page="trading"/>
            <Trading/>
            <Footer/>
        </div>
    )
}

export default index
