import React,{useEffect} from 'react'

//Assets==============================
import './css/Microfinance.css'
import tradingIcon from '../../img/stock.png'
import microcreditIcon from '../../img/heart.png'
import bitbetterIcon from '../../img/save.png'
import familyphonePhoto from '../../img/familyphone.jpg'
import africanPhoto from '../../img/african.jpg'
import sdgPhoto from '../../img/sdg.png'

const Microfinance = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    },[])
    return (
        <div className="Microfinance">
            <div className="MicrofinanceContentDiv">
                <div className="mfbanner">
                    <div></div>
                    <div>
                        <h1>
                            Contributing towards <br/>
                            a better future
                        </h1>
                        <p>
                        Creating opportunities through offering loans to people in rural areas with no access to banking
                        </p>
                    </div>
                </div>
                <div className="mfhiw">
                    <h1>How it works</h1>
                    <div className="hiwSteps">
                        <div>
                            <img src={tradingIcon} alt="stock" />
                            <h2>Treding</h2>
                            <p>We use big data and AI to profit from cryptocurrency trading</p>
                        </div>
                        <div>
                            <img src={microcreditIcon} alt="stock" />
                            <h2>Micro-credit</h2>
                            <p>We re-invest in micro-credit to fight poverty and provide opportunities</p>
                        </div>
                        <div>
                            <img src={bitbetterIcon} alt="stock" />
                            <h2>Bitbetter</h2>
                            <p>We aim to contribute to a bitbetter and more sustainable world of the future</p>
                        </div>
                    </div>
                </div>
                <div className="allInOneDiv">
                    <h1>Partner with us</h1>
                    <p>
                    Bitbetter partners with local microfinance institutions, social enterprises, NGOs and schools to provide opportunities and credit to poor people living in rural areas with no access to the traditional banking system.
                    </p>
                    <p>
                    In order to expand our reach and improve the lives of people in different communities, we are interested in exploring partnership opportunities if you have our same vision: contributing towards a better and more sustainable future.
                    </p>
                    <div className="allInOneDIvBtn">Get in touch</div>
                </div>
                <div className="mfItem">
                    <div className="mfItemImg">
                        <img src={familyphonePhoto} alt="family looking at phone" />
                    </div>
                    <div className="mfItemDetails">
                        <h1>Digitise microfinance</h1>
                        <p>We believe cryptocurrencies provide a unique opportunity to offer microcredit in areas of the world where banking institutions do not operate.</p>
                        <p>We reach people in rural areas by transferring loans directly on their mobile phone, which allows us to avoiding intermediaries, lower transaction costs and make landing faster, easier and cheaper.</p>
                    </div>
                </div>
                <div className="mfItem">
                    <div className="mfItemImg">
                        <img src={africanPhoto} alt="family looking at phone" />
                        <div className="imgText">
                            <h1>SDG 10</h1>
                            <h2>REDUCED<br/>INEQUALITYES</h2>
                            <img src={sdgPhoto} alt="sdg" />
                        </div>
                    </div>
                    <div className="mfItemDetails">
                        <h1>Building a better world</h1>
                        <p>Bitbetter is inspired by the 17 Sustainable Development Goals (SDGs) set out by the United Nations and formally adopted by 193 countries in 2015.</p>
                        <p>By providing microcredit to people with no access to banking, we are contributing towards the ambitious goals of reducing inequalities, erasing financial discrimination and promoting prosperity for everyone, regardless of gender, economic status, religion, race and nationality.</p>
                        <p>We are helping to create a better world and more equitable future for all, in line with the UN’s vision.</p>
                    </div>
                </div>
                <div>
                    <iframe src="https://www.youtube.com/embed/kGcrYkHwE80" frameborder="0" width="100%" height="560px"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Microfinance
