import React from 'react'

//Components==============================
import Navbar from '../_global/Navbar'
import Crypto from './Crypto'
import Footer from '../_global/Footer'

const index = () => {
    return (
        <div className="CryptoPage">
            <Navbar page="trading"/>
            <Crypto/>
            <Footer/>
        </div>
    )
}

export default index
