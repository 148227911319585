import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  customSort,
  numberWithCommas,
} from "../../service/staticDataService/Util";

//Assets==============================
import "./css/Trading.css";
// import calenderIcon from '../_global/icons/calendar.png'
import rightArrowIcon from "../_global/icons/rightArrow.svg";

const Trading = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = () => {
      // const api_key = "922adc067ea73ca2264f44d224fd48482bd6ef7c"
      // const coins = "BTC,ETH,BNB,ADA,XRP,DOGE,UNI,LTC,XLM,XMR"
      // const url = `https://api.nomics.com/v1/currencies/ticker?key=${api_key}&interval=1d&ids=${coins}&convert=EUR`
      const url = `https://api.bitbettercapital.com/home`;
      //   var config = {
      //     headers: { "Access-Control-Allow-Origin": "*" },
      //   };
      axios
        .get(url)
        .then((res) => {
          console.log("Suc", res);
          let resArray = Object.values(res.data);
          let filtered = customSort(resArray);
          setData([...filtered]);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length) {
      console.log(data[0]);
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Trading">
      <div className="Trading__contentDiv">
        <div className="Trading__coinListingDiv">
          {data.map((coin) => {
            return (
              <div className="Trading__coinDiv">
                <div className="Trading__coinDiv__left">
                  <div className="coinDiv">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/img/coins/${coin?.code?.toLowerCase()}.png`
                      }
                      alt=""
                      className="coinIcon"
                    />
                  </div>
                  <div className="coinDetails">
                    <div className="coinName">
                      {coin?.name} ({coin?.code})
                    </div>
                    <div className="coinPrice">
                      $ {numberWithCommas(parseFloat(coin?.last_price))}
                    </div>
                    <div
                      className={
                        parseFloat(coin?.change) >= 0
                          ? "coinIncrement"
                          : "coinDecrement"
                      }
                    >
                      {coin?.change}
                    </div>
                  </div>
                </div>
                <div className="Trading__coinDiv__right">
                  <div className="coinChart"></div>
                  <Link
                    to={`crypto/${coin?.code?.toLowerCase()}`}
                    className="arrowLink"
                  >
                    <img
                      src={rightArrowIcon}
                      alt=""
                      className="rightArrowIcon"
                    />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Trading;
