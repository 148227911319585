import React from 'react'
import {Link} from 'react-router-dom'

//Assets==============================
import './css/Footer.css'
import facebookIcon from '../_global/icons/facebook.png'
import twitterIcon from '../_global/icons/twitter.png'
import youtubeIcon from '../_global/icons/youtube.png'
import linkedinIcon from '../_global/icons/linkedin.png'

const Footer = () => {
    return (
        <footer>
            <div className="footerContentContainer">
                <div className="footer__top">
                    <div>
                        <Link to='/' className="pageLink">Trading</Link>
                    </div>
                    <div>
                        <Link to='/microfinance' className="pageLink">Microfinance</Link>
                    </div>
                    <div>
                        <Link to='/about' className="pageLink">About</Link>
                    </div>
                </div>
            </div>
            <div className="footerContentContainer">
                <div className="footer__bottom">
                    <div className="top">
                        <Link to="/" className="nav__logo">
                            <img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt="" />
                            <div className="logo__text">bitbetter</div>
                        </Link>
                        <div className="socialMedia">
                            <a href="www.facebook.com" target="_blank" className="socialIcon">
                                <img src={facebookIcon} alt="fb" />
                            </a>
                            <a href="www.twitter.com" target="_blank" className="socialIcon">
                                <img src={twitterIcon} alt="twt" />
                            </a>
                            <a href="www.youtube.com" target="_blank" className="socialIcon">
                                <img src={youtubeIcon} alt="yt" />
                            </a>
                            <a href="www.linkedin.com" target="_blank" className="socialIcon">
                                <img src={linkedinIcon} alt="lin" />
                            </a>
                        </div>
                    </div>
                    <div className="bottom">
                        <div>&copy; {new Date().getFullYear()} bitbettercapital.com</div>
                        <div>{"</>"} Developed by Arindam Hazra</div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
