import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";

//Assets==============================
import "./css/Crypto.css";
// import calenderIcon from '../_global/icons/calendar.png'
// import bitcoinIcon from '../_global/icons/bitcoin-color.png'
import downArrow from "../_global/icons/downArrow.png";

//Components==============================
import TweetContents from "./TweetContents";
import PriceChart from "./PriceChart";

const Crypto = () => {
  // const [range, setRange] = useState('ALL')

  const [coinData, setCoinData] = useState({});

  const { code } = useParams();

  useEffect(() => {
    const fetchCoinData = () => {
      const url = `https://api.bitbettercapital.com/home`;
      axios
        .get(url)
        .then((res) => {
          console.log("Suc", res);
          let cObj = res.data?.[`${code.toUpperCase()}`];
          setCoinData({ ...cObj });
        })
        .catch((err) => {
          console.log("Err", err);
        });
    };
    fetchCoinData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Crypto">
      <div className="Trading__contentDiv">
        <div className="Crypto__coinDetailsDiv">
          <div className="left">
            <img
              src={process.env.PUBLIC_URL + `/img/coins/${code}.png`}
              alt=""
            />
            <div>
              <div className="coinName">{coinData?.name}</div>
              <div className="coinCode">{coinData?.code}</div>
            </div>
          </div>
          <div className="right">
            <div className="coinPrice">$ {coinData?.last_price}</div>
            <div className="coinUpDown">
              <img src={downArrow} alt="" />
              {coinData?.change}
            </div>
          </div>
        </div>
        {/* <div className="dateRangeDiv">
                    <div className={`dateRange__item ${(range === 'D1' && 'active__dateRange')?.toString()}`}onClick={()=>setRange('D1')}>1D</div>
                    <div className={`dateRange__item ${(range === 'D7' && 'active__dateRange')?.toString()}`}onClick={()=>setRange('D7')}>7D</div>
                    <div className={`dateRange__item ${(range === 'M1' && 'active__dateRange')?.toString()}`}onClick={()=>setRange('M1')}>1M</div>
                    <div className={`dateRange__item ${(range === 'M3' && 'active__dateRange')?.toString()}`}onClick={()=>setRange('M3')}>3M</div>
                    <div className={`dateRange__item ${(range === 'Y1' && 'active__dateRange')?.toString()}`}onClick={()=>setRange('Y1')}>1Y</div>
                    <div className={`dateRange__item ${(range === 'YTD' && 'active__dateRange')?.toString()}`}onClick={()=>setRange('YTD')}>YTD</div>
                    <div className={`dateRange__item ${(range === 'ALL' && 'active__dateRange')?.toString()}`}onClick={()=>setRange('ALL')}>ALL</div>
                    <div className={`dateRange__item ${(range === 'DR' && 'active__dateRange')?.toString()}`} onClick={()=>setRange('DR')}>
                        <img src={calenderIcon} alt="" />
                    </div>
                    <div className={`dateRange__item log__div ${(range === 'LOG' && 'active__dateRange')?.toString()}`}onClick={()=>setRange('LOG')}>LOG</div> 
                </div>*/}

        <div className="CryptoChart">
          <PriceChart code={code} />
        </div>
        <div className="CryptoTweets">
          <TweetContents title="Top tweets of the day" />
          <TweetContents title="Top tweets of the week" />
        </div>
        <div className="commentDiv">
          <h2>Comments</h2>
          <div className="comments">
            <div className="commentThread">
              <div className="comment">
                <div className="name">John Doe</div>
                <div className="text">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Dicta neque blanditiis in, quisquam dolores qui harum facilis
                  molestiae excepturi temporibus.
                </div>
                <div className="actions">
                  <div>Like (5)</div>
                  <div>Reply</div>
                </div>
              </div>
              <div className="comment subcomment">
                <div className="name">Dan Tom</div>
                <div className="text">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Dicta neque blanditiis in, quisquam dolores qui harum facilis
                  molestiae excepturi temporibus.
                </div>
              </div>
              <div className="comment subcomment">
                <div className="name">John Doe</div>
                <div className="text">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Dicta neque blanditiis in.
                </div>
              </div>
              <div className="writeSubcomment">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <textarea rows="1" placeholder="Reply to John Doe"></textarea>
                  <button>Send</button>
                </form>
              </div>
            </div>
            <div className="comment">
              <div className="name">Arindam Hazra</div>
              <div className="text">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dicta
                neque blanditiis in, quisquam dolores qui harum facilis
                molestiae excepturi temporibus.
              </div>
              <div className="actions">
                <div>Like (2)</div>
                <div>Reply</div>
              </div>
            </div>
            <div className="writeSubcomment">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <textarea
                  rows="1"
                  placeholder="Reply to Arindam Hazra"
                ></textarea>
                <button>Send</button>
              </form>
            </div>
          </div>
          <div className="writeComments">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <textarea rows="3" placeholder="Write your comment"></textarea>
              <button>Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crypto;
