import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'

//Assets===============================
import './css/MobileMenu.css'
import crossIcon from './icons/cross.svg'
import arrow from './icons/rightArrow.svg'

const MobileMenu = ({visible,close,page}) => {

    const [tradingSubMenu, setTradingSubMenu] = useState(false)
    // const [microfinanceSubMenu, setMicrofinanceSubMenu] = useState(false)

    useEffect(() => {
        const hideOnScroll = () => {
            setTradingSubMenu(false)
            close()
        }
        window.addEventListener('scroll', ()=>hideOnScroll());
        return () => {
            window.removeEventListener('scroll', ()=>hideOnScroll());
        }
    }, [])

    return (
        <div className={visible ? 'mobileMenu mobileMenu__open' : 'mobileMenu mobileMenu__close'}>
            <div className="mobilemenuWrapper">
                <div className="menu__cross" onClick={()=>close(false)}>
                    <img src={crossIcon} alt="X" />
                </div>
                <div className="menu__container">
                    <div className={`menu__item ${(page === "trading" && 'active__menu__link')?.toString()}`} onClick={()=>setTradingSubMenu(!tradingSubMenu)}>Trading
                        <img className={tradingSubMenu ? "open": ""} src={arrow} alt="" />
                    </div>
                    {tradingSubMenu &&
                        <div className="submenu">
                            <Link to='/' className="submenuLink">All</Link>
                            <Link to='/crypto/btc' className="submenuLink">Bitcoin</Link>
                            <Link to='/crypto/btc' className="submenuLink">Ethereum</Link>
                            <Link to='/crypto/btc' className="submenuLink">Ripple</Link>
                        </div>
                    }
                    <Link to='/microfinance' className={`menu__item ${(page === "microfinance" && 'active__menu__link')?.toString()}`}>Microfinance</Link>
                    <Link to='/about' className={`menu__item ${(page === "about" && 'active__menu__link')?.toString()}`}>About</Link>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu
