import React from 'react'

//Components==============================
import Navbar from '../_global/Navbar'
import Microfinance from './Microfinance'
import Footer from '../_global/Footer'

const index = () => {
    return (
        <div className="MicrofinancePage">
            <Navbar page="microfinance"/>
            <Microfinance/>
            <Footer/>
        </div>
    )
}

export default index
