import { ORDERED_COINS } from "./Constant";

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const customSort = (coinsArray) => {
  let filtered = [];
  ORDERED_COINS.forEach((order) => {
    coinsArray.forEach((coin) => {
      if (coin?.code === order) {
        filtered.push(coin);
      }
    });
  });

  return filtered;
};
