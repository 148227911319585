import React from 'react'

//Components==============================
import Navbar from '../_global/Navbar'
import About from './About'
import Footer from '../_global/Footer'

const index = () => {
    return (
        <div className="MicrofinancePage">
            <Navbar page="about"/>
            <About/>
            <Footer/>
        </div>
    )
}

export default index
