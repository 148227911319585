import React,{useState} from 'react'
import './css/Navbar.css'
import {Link} from 'react-router-dom'

//Assets=====================================
import toggleIcon from './icons/toggleIcon.svg'

//Components================================
import MobileMenu from './MobileMenu'

const Navbar = ({page}) => {

    const [mobileMenuOpened, setMobileMenuOpened] = useState(false)

    return (
        <nav className="Navbar">
            <MobileMenu visible={mobileMenuOpened} close={setMobileMenuOpened} page={page}/>
            <div className="container Navbar__items">
                <div className="Navbar__left">
                    <Link to="/" className="nav__logo">
                        <img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt="" />
                        <div className="logo__text">bitbetter</div>
                    </Link>
                </div>
                <div className="Navbar__right">
                    <div className="Navbar__desktop__menu">
                        <Link to='/' className={`menu__item ${(page === "trading" && 'active__menu__item')?.toString()}`}>Trading</Link>
                        <Link to='/microfinance' className={`menu__item ${(page === "microfinance" && 'active__menu__item')?.toString()}`}>Microfinance</Link>
                        <Link to='/about' className={`menu__item ${(page === "about" && 'active__menu__item')?.toString()}`}>About</Link>
                    </div>
                    <div className="nav__toggle__icon">
                        <img src={toggleIcon} alt="" onClick={()=> setMobileMenuOpened?.(true)} />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
