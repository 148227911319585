import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import "./css/PriceChart.css";

const PriceChart = ({ code }) => {
  const [opt, setOpt] = useState({});
  const [data5, setData5] = useState([]);
  const [dataDay, setDataDay] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [dataMode, setDataMode] = useState("5min");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `https://api.bitbettercapital.com/${code.toUpperCase()}`;
      let tempData5 = [];
      let tempDataDay = [];
      axios
        .get(url)
        .then((res) => {
          console.log("Suc", res);
          let arr5 = res.data?.[`5min`];
          let arrDay = res.data?.[`day`];
          arr5.forEach((item) => {
            tempData5.push([parseFloat(item?.time), parseFloat(item?.price)]);
          });

          arrDay.forEach((item) => {
            tempDataDay.push([parseFloat(item?.time), parseFloat(item?.price)]);
          });
          //   setData([...cArr]);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Err", err);
        })
        .finally(() => {
          setData5(tempData5);
          setDataDay(tempDataDay);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    setOpt({
      credits: {
        enabled: false,
      },
      chart: {
        height: "50%",
      },
      xAxis: {
        gapGridLineWidth: 0,
      },
      yAxis: {
        lineWidth: 0,
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        lineColor: "transparent",
      },
      rangeSelector: false,

      series: [
        {
          name: "Price",
          type: "area",
          color: "#fdab2f",
          data: dataMode === "5min" ? data5 : dataMode === "day" ? dataDay : [],
          gapSize: 5,
          tooltip: {
            valueDecimals: 4,
          },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[3]],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[3])
                  .setOpacity(0.2)
                  .get("rgba"),
              ],
            ],
          },
          fontSize: "1.1rem",
          threshold: null,
        },
      ],
    });
  }, [dataMode, isLoading]);

  return (
    <>
      {isLoading && (
        <Loader
          type="Oval"
          color="#111"
          height={100}
          width={100}
          className="chartLoader"
        />
      )}
      {!isLoading && (
        <div>
          <div className="rangeSelectors">
            <div onClick={() => setDataMode("5min")}>5min</div>
            <div onClick={() => setDataMode("hour")}>Hour</div>
            <div onClick={() => setDataMode("day")}>Day</div>
            <div onClick={() => setDataMode("week")}>Week</div>
            {/* <div onClick={() => setDataMode("day")}>Month</div> */}
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={opt}
          />
        </div>
      )}
    </>
  );
};

export default PriceChart;
