import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'

//Assets==============================
import './css/About.css'
import linkedinIcon from '../../img/linkedin.png'
import founderPhoto from '../../img/founder.png'
import strategistPhoto from '../../img/strategist.png'
import sustainabilityPhoto from '../../img/sustainability.png'
import developerPhoto from '../../img/developer.png'
import partnershipsPhoto from '../../img/partnerships.png'
import advisorPhoto from '../../img/advisor.png'

const Microfinance = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    },[])
    return (
        <div className="About">
            <div className="AboutContentDiv">
                <div className="abtbanner">
                    <div></div>
                    <div>
                        <h1>Sustainable growth for everyone, everywhere</h1>
                        <p>Creating opportunities through offering loans to people in rural areas with no access to banking</p>
                    </div>
                </div>
                <div className="ourVision">
                    <h1>Out vision</h1>
                    <p>Bitbetter is a social enterprise that aims to solve the challenges related to trading and financing. Our vision is to build a better and more sustainable world of the future, through cryptocurrencies and microcredit.</p>
                    <p>The way we do this is simple. From one side, we leverage big data and artificial intelligence to help traders to make gains from the cryptocurrency market. From the other side, we re-invest the profits to finance projects of people with no access to banking or credit history across the world.</p>
                </div>
                <div className="ourTeam">
                    <h1>Our Team</h1>
                    <div className="teamMember">
                        <div className="memberPhoto">
                            <img src={founderPhoto} alt="founder" />
                        </div>
                        <div className="memberText">
                            <div className="memberNameAndPosition">
                                <span className="memberName">Daniel Tomaselli</span>,
                                <span className="memberPosition">Founder</span>
                            </div>
                            <div className="memberMessage">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, esse illum hic odio eius laborum optio a corporis ex? Nemo.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni eligendi et aspernatur culpa, laudantium iusto fugiat iure quis veniam! Assumenda at eum animi sed, illo officia voluptas doloribus, nobis repellat ex nulla deleniti! Debitis itaque ex iusto qui, illo veniam.</p>
                            </div>
                            <a href="https://www.linkedin.com/in/tomasellidaniel/">
                                <img src={linkedinIcon} alt="linkedin" />
                            </a>
                        </div>
                    </div>
                    <div className="teamMember">
                        <div className="memberPhoto">
                            <img src={ strategistPhoto} alt="strategist" />
                        </div>
                        <div className="memberText">
                            <div className="memberNameAndPosition">
                                <span className="memberName">Gabriele D’Amore</span>,
                                <span className="memberPosition">Strategist</span>
                            </div>
                            <div className="memberMessage">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, esse illum hic odio eius laborum optio a corporis ex? Nemo.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni eligendi et aspernatur culpa, laudantium iusto fugiat iure quis veniam! Assumenda at eum animi sed, illo officia voluptas doloribus, nobis repellat ex nulla deleniti! Debitis itaque ex iusto qui, illo veniam.</p>
                            </div>
                            <a href="https://www.linkedin.com/in/gabriele-d-amore-485a707b/">
                                <img src={linkedinIcon} alt="linkedin" />
                            </a>
                        </div>
                    </div>
                    <div className="teamMember">
                        <div className="memberPhoto">
                            <img src={ sustainabilityPhoto} alt="founder" />
                        </div>
                        <div className="memberText">
                            <div className="memberNameAndPosition">
                                <span className="memberName">LeBo Likhoeli</span>,
                                <span className="memberPosition">Sustainability</span>
                            </div>
                            <div className="memberMessage">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, esse illum hic odio eius laborum optio a corporis ex? Nemo.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni eligendi et aspernatur culpa, laudantium iusto fugiat iure quis veniam! Assumenda at eum animi sed, illo officia voluptas doloribus, nobis repellat ex nulla deleniti! Debitis itaque ex iusto qui, illo veniam.</p>
                            </div>
                            <a href="https://www.linkedin.com/in/llikhoeli/">
                                <img src={linkedinIcon} alt="linkedin" />
                            </a>
                        </div>
                    </div>
                    <div className="teamMember">
                        <div className="memberPhoto">
                            <img src={ developerPhoto} alt="founder" />
                        </div>
                        <div className="memberText">
                            <div className="memberNameAndPosition">
                                <span className="memberName">Arindam Hazra</span>,
                                <span className="memberPosition">Developer</span>
                            </div>
                            <div className="memberMessage">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, esse illum hic odio eius laborum optio a corporis ex? Nemo.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni eligendi et aspernatur culpa, laudantium iusto fugiat iure quis veniam! Assumenda at eum animi sed, illo officia voluptas doloribus, nobis repellat ex nulla deleniti! Debitis itaque ex iusto qui, illo veniam.</p>
                            </div>
                            <a href="https://www.linkedin.com/in/iamarindamhazra/">
                                <img src={linkedinIcon} alt="linkedin" />
                            </a>
                        </div>
                    </div>
                    <div className="teamMember">
                        <div className="memberPhoto">
                            <img src={ partnershipsPhoto} alt="founder" />
                        </div>
                        <div className="memberText">
                            <div className="memberNameAndPosition">
                                <span className="memberName">Nana Prempeh</span>,
                                <span className="memberPosition">Partnerships</span>
                            </div>
                            <div className="memberMessage">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, esse illum hic odio eius laborum optio a corporis ex? Nemo.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni eligendi et aspernatur culpa, laudantium iusto fugiat iure quis veniam! Assumenda at eum animi sed, illo officia voluptas doloribus, nobis repellat ex nulla deleniti! Debitis itaque ex iusto qui, illo veniam.</p>
                            </div>
                            <a href="https://www.linkedin.com/in/nana-prempeh-4604032b/">
                                <img src={linkedinIcon} alt="linkedin" />
                            </a>
                        </div>
                    </div>
                    <div className="teamMember">
                        <div className="memberPhoto">
                            <img src={ advisorPhoto} alt="founder" />
                        </div>
                        <div className="memberText">
                            <div className="memberNameAndPosition">
                                <span className="memberName">Alessandro Moretti</span>,
                                <span className="memberPosition">Advisor</span>
                            </div>
                            <div className="memberMessage">
                                <p>Alessandro is a media advisor with more than 15 years of experience leading large scale digital and data technology projects for companies including Coca Cola, The Times and Discovery Channel.</p>
                                <p>"Travelling across South America by motorbike for six months was an eye opening experience and since then I am keen to make a positive impact to society."</p>
                            </div>
                            <a href="https://www.linkedin.com/in/alemoretti/">
                                <img src={linkedinIcon} alt="linkedin" />
                            </a>
                        </div>
                    </div>
                    
                              
                </div>
            </div>
        </div>
    )
}

export default Microfinance
