import './App.css';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";

//Components================================================
import TradingPage from './components/TradingPage'
import MicrofinancePage from './components/MicrofinancePage'
import AboutPage from './components/AboutPage'
import CryptoPage from './components/CryptoPage'

function App() {
  return (
    <Router>
    <Switch>
      <Route exact path="/">
        <TradingPage/>
      </Route>
      <Route path="/crypto/:code">
        <CryptoPage/>
      </Route>
      <Route path="/microfinance">
        <MicrofinancePage page="/microfinance"/>
      </Route>
      <Route path="/about" page="/about">
        <AboutPage/>
      </Route>
      <Route path="/contact">
        Contact Us
      </Route>
    </Switch>
  </Router>
  
  );
}

export default App;
