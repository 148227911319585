import React from 'react'

const TweetContents = ({title}) => {
    return (
        <div className="tweetsDiv">
            <h2>{title}</h2>
            <div className="tweetItem">
                <div className="tweetTime">11:45AM</div>
                <a href="https://twitter.com/" className="tweetText">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex minus vel ullam cum. Quas quisquam sunt atque eaque.</a>
            </div>
            <div className="tweetItem">
                <div className="tweetTime">11:45AM</div>
                <a href="https://twitter.com/" className="tweetText">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates, commodi iusto facere tenetur adipisci labore.</a>
            </div>
            <div className="tweetItem">
                <div className="tweetTime">11:45AM</div>
                <a href="https://twitter.com/" className="tweetText">Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi voluptas alias sequi debitis tempora sint! A reiciendis repellat cum earum veritatis quas sequi quo dolores.</a>
            </div>
            <div className="tweetItem">
                <div className="tweetTime">11:45AM</div>
                <a href="https://twitter.com/" className="tweetText">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur saepe eum culpa dolores illo? Dicta, quis a.</a>
            </div>
            <div className="tweetItem">
                <div className="tweetTime">11:45AM</div>
                <a href="https://twitter.com/" className="tweetText">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur saepe eum culpa dolores illo? Dicta, quis a.</a>
            </div>
            <div className="tweetItem">
                <div className="tweetTime">11:45AM</div>
                <a href="https://twitter.com/" className="tweetText">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nesciunt dolorum quam libero? Esse, unde ducimus earum quis labore nesciunt quia?</a>
            </div>
            {/* <div className="tweetItem">
                <div className="tweetTime">11:45AM</div>
                <a href="https://twitter.com/" className="tweetText">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ullam fuga dolor quasi et laudantium.</a>
            </div>
            <div className="tweetItem">
                <div className="tweetTime">11:45AM</div>
                <a href="https://twitter.com/" className="tweetText">Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsa ipsam cum quibusdam deleniti alias sint, ipsum itaque et!</a>
            </div>
            <div className="tweetItem">
                <div className="tweetTime">11:45AM</div>
                <a href="https://twitter.com/" className="tweetText">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ullam fuga dolor quasi et laudantium.</a>
            </div>
            <div className="tweetItem">
                <div className="tweetTime">11:45AM</div>
                <a href="https://twitter.com/" className="tweetText">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed temporibus porro quam culpa.</a>
            </div> */}
        </div>

    )
}

export default TweetContents
