export const ORDERED_COINS = [
  "BTC",
  "ETH",
  "BNB",
  "ADA",
  "XRP",
  "DOG",
  "LTC",
  "UNI",
  "XLM",
  "XMR",
];
